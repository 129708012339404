import { useEffect, useState } from "react";
import SectionHeader from "../../../../components/SectionHeader";
import FieldWrapper from "../../../../components/formInputs/FieldWrapper";
import { customStyles } from "../../../../utils/SelectStyles";
import { TOptions } from "i18next";
import { useLocation } from "react-router-dom";

const BasicInfoForm = ({ productData, formik, t, serviceProviders }: any) => {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const sp = urlParams?.get('sp');


    const [selectedDuration, setSelectedDuration] = useState<any>(0);
    const durationOptions = [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" }]

    useEffect(() => {
        setSelectedDuration(productData?.profit_rate?.length)
    }, [productData])
    

    return (
        <>
            <SectionHeader title={t("basicDetails")}>
                <div className="inputs_group">
                    <FieldWrapper
                        customPadding
                        title={t("serviceProvider")}
                        inputName={"service_provider_id"}
                        inputError={formik.errors.service_provider_id as string}
                        inputTouched={formik.touched.service_provider_id as boolean}
                        inputPlaceholder={t("selectServiceProvider")}
                        options={serviceProviders}
                        defaultValue={ serviceProviders?.find((option: TOptions) => option.value == (sp || productData?.service_provider_id))}
                        key={ serviceProviders?.find((option: TOptions) => option.value == (sp || productData?.service_provider_id))}
                        onChange={(newValue) => {
                            newValue && formik.setFieldValue("service_provider_id", newValue.value);

                        }}
                        selectStyle={customStyles}
                        select
                        disabled={sp ? true : false}

                    />
                    <FieldWrapper noPadding />
                    <FieldWrapper noPadding />

                </div>
                <div className="inputs_group">
                    <FieldWrapper
                        showUnit
                        unit={t("year")}
                        title={t("minimumAge")}
                        inputName={"minimum_age"}
                        inputPlaceholder={`${t("ex")} 21`}
                        inputError={formik.errors.maximum_age as string}
                        inputTouched={formik.touched.minimum_age as boolean}
                        input
                        value={formik?.values?.minimum_age}
                        type="number"
                    />
                    <FieldWrapper
                        showUnit
                        unit={t("year")}
                        title={t("maximumAge")}
                        inputName={"maximum_age"}
                        inputPlaceholder={`${t("ex")} 60`}
                        inputError={formik.errors.maximum_age as string}
                        inputTouched={formik.touched.maximum_age as boolean}
                        input
                        value={formik?.values?.maximum_age}
                        type="number"
                    />
                </div>
            </SectionHeader>
            <SectionHeader title={t("financialDetails")}>
                <div className="inputs_group">
                    <FieldWrapper
                        showUnit
                        unit="%"
                        title={t("downPayment")}
                        inputName={"down_payment"}
                        inputPlaceholder={`${t("ex")} 2.5`}                        inputError={formik.errors.down_payment as string}
                        inputTouched={formik.touched.down_payment as boolean}
                        input
                        value={formik?.values?.down_payment}
                        type="number"
                    />
                    <FieldWrapper
                        showUnit
                        unit="%"
                        title={t("adminFee")}
                        inputName={"admin_fee"}
                        inputPlaceholder={`${t("ex")} 21`}
                        inputError={formik.errors.admin_fee as string}
                        inputTouched={formik.touched.admin_fee as boolean}
                        input
                        value={formik?.values?.admin_fee}
                        type="number"
                    />
                </div>
                <div className="inputs_group">
                    <FieldWrapper
                        customPadding
                        title={t("financialDuration")}
                        inputName={"duration_to"}
                        inputError={formik.errors.duration_to as string}
                        inputTouched={formik.touched.duration_to as boolean}
                        inputPlaceholder={t("selectDuration")}
                        desc={t("upTo5Years")}
                        options={durationOptions}
                        onChange={(e) => {
                            formik.setFieldValue("duration_to", e?.value);
                            formik.setFieldValue("profit_rate", Array.from({ length: Number(e?.value) }, () => ""));
                            setSelectedDuration(e?.value);
                        }}
                        defaultValue={productData && durationOptions?.find((option: TOptions) => option.value == productData?.duration_to)}
                        selectStyle={customStyles}
                        select
                        key={productData && durationOptions?.find((option: TOptions) => option.value == productData?.duration_to)?.value}
                    />
                    <FieldWrapper noPadding />
                    <FieldWrapper noPadding />
                </div>
                <div className="inputs_group">
                    {Array.from({ length: Number(selectedDuration) }, (_, index) => (
                        <FieldWrapper
                            showUnit
                            unit="%"
                            title={`${index + 1} ${t("year")}`}
                            inputName={`profit_rate.${index}`}
                            inputPlaceholder={`${t("ex")} 2`}         
                            inputError={formik.errors.profit_rate && formik.errors.profit_rate[index]}
                            inputTouched={formik.touched.profit_rate && formik.touched.profit_rate[index]}
                            input
                            key={index}
                            type="number"
                        />
                    ))}
                    {Array.from({ length: 5 - Number(selectedDuration) }, (_, index) => (
                        <FieldWrapper noPadding />
                    ))}

                </div>

            </SectionHeader>
            <SectionHeader title={t("insuranceDetails")}>
                <div className="inputs_group">
                    <FieldWrapper
                        showUnit
                        unit="%"
                        title={t("insurance")}
                        inputName={"insurance_fee"}
                        inputPlaceholder={`${t("ex")} 2`}                        inputError={formik.errors.insurance_fee as string}
                        inputTouched={formik.touched.insurance_fee as boolean}
                        input
                        value={formik?.values?.insurance_fee}
                    />
                    <FieldWrapper noPadding />
                    <FieldWrapper noPadding />
                </div>

                <div className="inputs_group">
                    <FieldWrapper
                        title={t("disclaimerEnglish")}
                        inputName={`disclaimer[en]`}
                        inputPlaceholder={t("disclaimerEnglish")}
                        inputError={formik?.errors?.disclaimer?.en}
                        inputTouched={formik?.touched?.disclaimer?.en}
                        textArea
                        maxLength={300}
                        value={formik?.values?.disclaimer?.en}
                        showMaxLengthHint
                    />
                    <FieldWrapper
                        title={t("disclaimerArabic")}
                        inputName={`disclaimer[ar]`}
                        inputPlaceholder={t("disclaimerArabic")}
                        inputError={formik?.errors.disclaimer?.ar}
                        inputTouched={formik?.touched?.disclaimer?.ar}
                        textArea
                        maxLength={300}
                        value={formik?.values?.disclaimer?.ar}
                        showMaxLengthHint
                    />
                </div>
            </SectionHeader>
        </>
    );
}

export default BasicInfoForm;