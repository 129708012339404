import { useField, useFormikContext } from "formik";
// import DatePicker, { DateObject } from "react-multi-date-picker";
// import TimePicker from "react-multi-date-picker/plugins/time_picker";
import TimePicker from 'react-time-picker';
import { useState } from 'react';

import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { ITimePickerFieldProps } from "../../types/Interfaces";

export const TimePickerField = ({ ...props }: ITimePickerFieldProps) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const [value, onChange] = useState(field?.value);
  return (
    <div className="time_picker_container">
      <div className="icon">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="21" height="21" viewBox="0 0 50 50">
          <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 24.984375 6.9863281 A 1.0001 1.0001 0 0 0 24 8 L 24 22.173828 A 3 3 0 0 0 22 25 A 3 3 0 0 0 22.294922 26.291016 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 23.708984 27.705078 A 3 3 0 0 0 25 28 A 3 3 0 0 0 28 25 A 3 3 0 0 0 26 22.175781 L 26 8 A 1.0001 1.0001 0 0 0 24.984375 6.9863281 z"></path>
        </svg>
      </div>

      <TimePicker
        {...field}
        {...props}
        onChange={(value) => {
          onChange(value)
          setFieldValue(field.name, value);
        }}
        value={value || field.value}
        disableClock
        format="hh:mm a"
        hourPlaceholder="HH"
        minutePlaceholder="MM"
        amPmAriaLabel="pm"
        className="time_picker"
        disabled={props?.disabled}
      />
    </div>
  )
}
