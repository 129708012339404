import { useTranslation } from "react-i18next";
import FieldWrapper from "../../../../components/formInputs/FieldWrapper";
import SectionHeader from "../../../../components/SectionHeader";
import { TOptions } from "../../../../types/types";
import { customStyles } from "../../../../utils/SelectStyles";
import { FormikProps } from "formik";
import RadioInput from "../../../../components/formInputs/RadioInput";
import { Nationalities } from "../../../../config/FixedData";
import { useSelector } from "react-redux";

interface IProps {
    formik: FormikProps<any>;
    workSectors: TOptions[]
    services: TOptions[]
}
const CustomerDetailsForm = ({ formik, workSectors, services }: IProps) => {

    const { t, i18n } = useTranslation()
    const { searchData } = useSelector((store: any) => store.findDeal)

    return (
        <SectionHeader title={t("customer_details")} customStyle="last no-margin">
            <div className="inputs_group">
                <FieldWrapper title={t("nationality")}>
                    <RadioInput
                        name={`nationality`}
                        onChange={(e: any) => formik.setFieldValue(`nationality`, e.target.value)}
                        value={formik?.values?.nationality}
                        key={formik?.values?.nationality}
                        data={(Nationalities as any)[i18n.language]}
                    />
                </FieldWrapper>
                <FieldWrapper noPadding />
            </div>
            <div className="inputs_group">
                <FieldWrapper
                    title={t("age")}
                    inputName={`age`}
                    inputError={formik?.errors?.age as string}
                    inputTouched={formik?.touched?.age as boolean}
                    inputPlaceholder={`${t("ex")} 30`}
                    input
                    type="number"
                    showUnit
                    unit={t("years")}
                />
                <FieldWrapper
                    title={t("salary")}
                    inputName={`salary`}
                    inputError={formik?.errors?.salary as string}
                    inputTouched={formik?.touched?.salary as boolean}
                    inputPlaceholder={`${t("ex")} 30000`}
                    input
                    type="number"
                    showUnit
                    unit={t("sar")}
                />
                <FieldWrapper
                    title={t("salaryTransfer")}
                    inputName={`salary_transfer_to`}
                    inputPlaceholder={t("selectServiceProvider")}
                    inputError={formik?.errors?.salary_transfer_to as string}
                    inputTouched={formik?.touched?.salary_transfer_to as boolean}
                    options={services}
                    onChange={(e) => { formik.setFieldValue("salary_transfer_to", e?.value) }}
                    selectStyle={customStyles}
                    select
                    defaultValue={searchData?.salary_transfer_to && services?.find((option: TOptions) => option.value == searchData?.salary_transfer_to)}
                    key={searchData?.salary_transfer_to && services?.find((option: TOptions) => option.value == searchData?.salary_transfer_to)}
                />
            </div>
            <div className="inputs_group">
                <FieldWrapper
                    customPadding
                    title={t("workSectors")}
                    inputName={`work_sector_id`}
                    inputError={formik?.errors?.work_sector_id as string}
                    inputTouched={formik?.touched?.work_sector_id as boolean}
                    inputPlaceholder={t("selectWorkSectors")}
                    options={workSectors}
                    onChange={(e: any) => {
                        formik.setFieldValue(`work_sector_id`, e?.value)
                    }}
                    selectStyle={customStyles}
                    select
                    defaultValue={searchData?.work_sector_id && workSectors?.find((option: TOptions) => option.value == searchData?.work_sector_id)}
                    key={searchData?.work_sector_id && workSectors?.find((option: TOptions) => option.value == searchData?.work_sector_id)}
                />
                <FieldWrapper
                    title={t("servicePeriod")}
                    inputName={`service_period`}
                    inputError={formik?.errors?.service_period as string}
                    inputTouched={formik?.touched?.service_period as boolean}
                    inputPlaceholder={`${t("ex")} 12`}
                    input
                    type="number"
                    showUnit
                    unit={t("month")}
                />
            </div>
            <div className="inputs_group">
                <FieldWrapper
                    title={t("financialDuration")}
                    inputName={`finance_duration`}
                    inputError={formik?.errors?.finance_duration as string}
                    inputTouched={formik?.touched?.finance_duration as boolean}
                    inputPlaceholder={`${t("ex")} 12`}
                    input
                    type="number"
                    showUnit
                    unit={t("month")}
                />
                <FieldWrapper
                    title={t("downPayment")}
                    inputName={`down_payment`}
                    inputError={formik?.errors?.down_payment as string}
                    inputTouched={formik?.touched?.down_payment as boolean}
                    inputPlaceholder={`${t("ex")} 12`}
                    input
                    type="number"
                    showUnit
                    unit={"%"}
                />
                <FieldWrapper
                    title={t("balloonPercentage")}
                    inputName={`balloon_percentage`}
                    inputError={formik?.errors?.balloon_percentage as string}
                    inputTouched={formik?.touched?.balloon_percentage as boolean}
                    inputPlaceholder={`${t("ex")} 12`}
                    input
                    type="number"
                    showUnit
                    unit={"%"}
                />
            </div>
            <div className="inputs_group">
                <FieldWrapper
                    title={t("admin_fee")}
                    inputName={`admin_fee`}
                    inputError={formik?.errors?.admin_fee as string}
                    inputTouched={formik?.touched?.admin_fee as boolean}
                    inputPlaceholder={`${t("ex")} 12`}
                    input
                    type="number"
                    showUnit
                    unit={t("month")}
                />
                <FieldWrapper
                    title={t("profit_rate")}
                    inputName={`profit_rate`}
                    inputError={formik?.errors?.profit_rate as string}
                    inputTouched={formik?.touched?.profit_rate as boolean}
                    inputPlaceholder={`${t("ex")} 12`}
                    input
                    type="number"
                    showUnit
                    unit={"%"}
                />
                <FieldWrapper
                    title={t("insurance_fee")}
                    inputName={`insurance_fee`}
                    inputError={formik?.errors?.insurance_fee as string}
                    inputTouched={formik?.touched?.insurance_fee as boolean}
                    inputPlaceholder={`${t("ex")} 12`}
                    input
                    type="number"
                    showUnit
                    unit={"%"}
                />
            </div>
        </SectionHeader>
    );
}

export default CustomerDetailsForm;