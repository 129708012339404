import { useDispatch } from "react-redux";
import { setBreadCrumbsData } from "../../../store/redux/breadCrumbsData";
import { useContext, useEffect, useState, useTransition } from "react";
import { useQuery } from "@tanstack/react-query";
import { generalGet } from "../../../API/api";
import TableSkeleton from "../../../components/loaders/TableSkeleton";
import ListHeader from "../../../components/ListHeader";
import SearchInputField from "../../../components/SearchInputField";
import { searchFilterLogic } from "../../../utils/HelperFunctions";
import { useTranslation } from "react-i18next";
import { authContext } from "../../../store/context/authContext";
import ApplicationsTableContainer from "../../../modules/applications/components/ApplicationsTableContainer";
import { IApplication } from "../../../modules/applications/types/interfaces";


const Applications = () => {
    const { catchError } = useContext(authContext)
    const { t, i18n } = useTranslation()
    const [selectedOption, setSelectedOption] = useState(null);
    const [searchInput, setSearchInput] = useState("")
    const [isPending, startTransition] = useTransition()
    const [refetch, setRefetch] = useState(false)
    const [applications, setApplications] = useState<IApplication[]>()
    const [shownList, setShownList] = useState<IApplication[]>()
    const dispatch = useDispatch()

    dispatch(setBreadCrumbsData({
        links: [{ label: t("applications"), path: "/applications" }],
        page_title: t("applications"),
    }))


    const { data, isSuccess, isLoading, error } = useQuery({
        queryKey: ["applications", refetch, i18n],
        queryFn: () => generalGet("/applications"),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        error && catchError(error)
    }, [error])

    useEffect(() => {
        const reqData = data?.data?.data
        isSuccess &&
            setApplications(reqData)
    }, [isSuccess, data])


    const tableHeaders = [
        { label: t("appNo"), customClass: "pro_id_col" },
        { label: t("make&model"), customClass: "text-center" },
        { label: t("Distributor"), customClass: "text-center" },
        { label: t("serviceProvider"), customClass: "registration_date_col text-center" },
        { label: t("step"), customClass: "text-center" },
        { label: t("status"), customClass: "text-center" },
        { label: t("lastUpdated"), customClass: "text-center" },
    ];

    useEffect(() => {
        setSelectedOption(null)
        setShownList(applications)
        if (searchInput) {
            const results = searchFilterLogic({ searchInput: searchInput, listOfData: applications, keys: ["id"] });
            startTransition(() => {
                setShownList(results)
            })
        }
    }, [applications, searchInput])


    if (isLoading) return <TableSkeleton columns={6} withoutButton />

    return (
        <div className="services-page-container">
            <ListHeader>
                <div className="filter_container">
                    <SearchInputField placeholder={t("search_by_App_No")} setSearchInput={setSearchInput} searchInput={searchInput} />

                </div>
            </ListHeader>
            <ApplicationsTableContainer
                tableHeaders={tableHeaders}
                data={shownList}
                noDataMessage={t("No_applications_found")}
                setRefetchData={setRefetch}
            />
        </div>
    );
}

export default Applications;