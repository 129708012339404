import { Formik, Form } from "formik";
import * as Yup from "yup";
import FieldWrapper from "../../../components/formInputs/FieldWrapper";
import SectionHeader from "../../../components/SectionHeader";
import Button from "../../../components/buttons/Button";
import { useContext, useEffect, useRef, useState } from "react";
import FormUpload from "../../../components/formInputs/FormUpload";
import { buildFormData, scrollToError } from "../../../utils/HelperFunctions";
import { generalCreate, generalGet } from "../../../API/api";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import FormSkeleton from "../../../components/loaders/FormSkeleton";
import { useTranslation } from "react-i18next";
import { authContext } from "../../../store/context/authContext";
import { IDistributor } from "../types/interfaces";
import { API_FILE_URL } from "../../../config/APIs";

const CreateDistributorForm = () => {
    const { id } = useParams()
    const formRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const [distributorData, setDistributorData] = useState<IDistributor | null>()
    const [shownImage, setShownImage] = useState<string | null>()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { catchError } = useContext(authContext)

    const { data, isSuccess, isLoading } = useQuery({
        queryKey: ["distributor data", id],
        queryFn: () => generalGet(`/distributors/${id}`),
        enabled: !!id,
    });

    useEffect(() => {
        const reqData = data?.data.data
        if (isSuccess) {
            setDistributorData(reqData)
            setShownImage(`${API_FILE_URL}/storage/${reqData?.logo.image_path}${reqData?.logo.name}`)
        }
    }, [isSuccess, data, id])

    useEffect(() => {
        if (!id) {
            setDistributorData(null)
            setShownImage(null)
        }
    }, [id])

    const handleSubmit = (values: any) => {
        let route = "/distributors/store"
        if (id) route = `/distributors/${id}/update`
        setLoading(true)
        const formData = new FormData()
        buildFormData(formData, values, "");
        generalCreate({ route, values: formData }).then(res => {
            toast.success(`${t("Distributor")} ${!id ? t("created") : t("updated")} ${t("successfully")}`)
            navigate("/distributors")
        }
        ).catch(error => {
            catchError(error, setLoading)
        })
    }

    const validationSchema =
        Yup.object({
            logo: Yup.mixed().required(t("required")),
            name: Yup.object().shape(
                {
                    ar: Yup.string().required(t("required")).matches(/^[\u0600-\u06FF\s]+$/, t("must_ar")).min(3, t("name_short")),
                    en: Yup.string().required(t("required")).matches(/^[A-Za-z\s]+$/, t("must_en")).min(3, t("name_short")),
                }
            ),
            address: Yup.object().shape(
                {
                    street: Yup.string().required(t("required")),
                    city: Yup.string().required(t("required")),
                }
            ),
            phone: Yup.string()
                .required(t("required"))
                .max(15, t("wrong_number"))
                .min(4, t("wrong_number")),
        });

    const initialValues: IDistributor = {
        name: { en: distributorData?.name?.en || "", ar: distributorData?.name?.ar || "" },
        address: distributorData?.address ? { street: distributorData?.address?.street || "", city: distributorData?.address.city || "" } : {
            street: "", city: ""
        },
        logo: distributorData ? `${API_FILE_URL}/storage/${distributorData?.logo.image_path}${distributorData?.logo.name}` : "",
        phone: distributorData?.phone || "",
    }

    if (isLoading) return <FormSkeleton featuredSections={1} />

    return (

        <div className={'form_section'} ref={formRef}>
            <Formik
                validateOnMount
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm, setFieldValue }) => {
                    let value = { ...values, _method: "POST" }
                    if (id) { value = { ...value, _method: "PUT" } }
                    handleSubmit({ ...value })
                }}
            >
                {(formik) => (
                    <>
                        <Form>
                            <SectionHeader title={t("dist_logo")}>
                                <div className="inputs_group">
                                    <FieldWrapper >
                                        <FormUpload
                                            errors={formik?.errors.logo as string}
                                            touched={formik?.touched?.logo as boolean}
                                            setShownImage={setShownImage}
                                            shownImage={shownImage!}
                                            formik={formik}
                                            name={`logo`}
                                        />
                                    </FieldWrapper>
                                    <FieldWrapper noPadding />
                                    <FieldWrapper noPadding />
                                    <FieldWrapper noPadding />
                                </div>
                            </SectionHeader>
                            <SectionHeader title={t("distributorDetails")} customStyle="last">
                                <div className="inputs_group">
                                    <FieldWrapper
                                        title={t("name_en")}
                                        inputName={`name[en]`}
                                        inputPlaceholder={t("name_en")}
                                        inputError={formik.errors.name?.en as string}
                                        inputTouched={formik.touched.name?.en as boolean}
                                        input
                                        customPadding
                                    />
                                    <FieldWrapper
                                        title={t("name_ar")}
                                        inputName={`name[ar]`}
                                        inputPlaceholder={"الاسم العربي"}
                                        inputError={formik.errors.name?.ar as string}
                                        inputTouched={formik.touched.name?.ar as boolean}
                                        input
                                        customPadding
                                        dir={"rtl"}
                                    />
                                </div>
                                <div className="inputs_group">
                                    <FieldWrapper
                                        title={t("phone")}
                                        inputName={"phone"}
                                        inputPlaceholder={t("phone")}
                                        inputError={formik.errors.phone as string}
                                        inputTouched={formik.touched.phone as boolean}
                                        input
                                        type="number"
                                    />
                                    <FieldWrapper
                                        title={t("city")}
                                        inputName={`address[city]`}
                                        inputPlaceholder={t("city_ex")}
                                        inputError={formik.errors.address?.city as string}
                                        inputTouched={formik.touched.address?.city as boolean}
                                        input
                                        customPadding
                                        border
                                    />
                                    <FieldWrapper
                                        title={t("street")}
                                        inputName={`address[street]`}
                                        inputPlaceholder={t("street_ex")}
                                        inputError={formik.errors.address?.street as string}
                                        inputTouched={formik.touched.address?.street as boolean}
                                        input
                                        customPadding
                                    />
                                </div>
                            </SectionHeader>
                            <div className="form_button reverse">
                                <Button
                                    loading={loading}
                                    onClick={() => { scrollToError(!formik.isValid, formRef) }}
                                >
                                    <span className="bold">{t("save")}</span>
                                </Button>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </div >

    );
}

export default CreateDistributorForm;