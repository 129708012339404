import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/buttons/Button";
import ListHeader from "../../../components/ListHeader";
import { useTranslation } from "react-i18next";
import CommonHighlights from "../../../modules/serviceProvider/components/CommonHighlights";
import DistributorInfo from "../../../modules/distributors/components/DistributorInfo";
import { generalGet } from "../../../API/api";
import { useDispatch } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { setBreadCrumbsData } from "../../../store/redux/breadCrumbsData";
import { useQuery } from "@tanstack/react-query";
import { authContext } from "../../../store/context/authContext";
import BookingsTable from "../../../modules/sales/components/BookingsTable";
import moment from "moment";

interface salesData {
    first_name: string
    last_name: string
    email: string
    mobile: string
    address_line: string
    created_at: string
    bookings: any
}

const SalesDetails = () => {
    const navigate = useNavigate()
    const {t, i18n} = useTranslation()
    const [shownList, setShownList] = useState()
    const dispatch = useDispatch()
    const [refetch, setRefetch] = useState(false)
    const { catchError } = useContext(authContext)
    const [salesData, setSalesData] = useState<salesData>()
    const { id } = useParams()


    dispatch(setBreadCrumbsData({
        links: [{ label: t("sales"), path: "/sales" }],
        page_title: t("sellerDetails"),
    }))
    

    const { data, isSuccess, isLoading, error } = useQuery({
        queryKey: ["sellerDetails", refetch, i18n],
        queryFn: () => generalGet(`/admin/users/${id}`),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        error && catchError(error)
    }, [error])

    useEffect(() => {
        const reqData = data?.data?.data
        console.log(reqData, "salesDetailsData");
        
        isSuccess && 
        setSalesData(reqData)
        // setServices(reqData.map((item: IService) => ({ ...item, serviceName: item.name[i18n.language as keyof typeof item.name] })))
    }, [isSuccess, data])
 

    const tableHeaders = [
        { label: t("appNo"), customClass: "pro_id_col" },
        { label: t("make&model"),customClass: "text-center" },
        { label: t("Distributor"),customClass: "text-center"},
        { label: t("serviceProvider"), customClass: "registration_date_col text-center" },
        { label: t("step"), customClass: "text-center" },
        { label: t("status"), customClass: "text-center" },
        { label: t("owner"), customClass: "text-center" },
        { label: t("actions"), customClass: "actions_col" }
    ];


//     if (isLoading || offersLoading) return <div>
//     <BasicInfoSkeleton />
//     <TableSkeleton columns={5} />
// </div>

const highlights = [
    {
        title: t("totalSales"),
        number: "230" ,
    },
    {
        title: t("totalSalesValue"),
        number: "113k " + t("sar"),
    },
    {
        title: t("activeApplications"),
        number: "23",
    },
    {
        title: t("lastBooking"),
        number: "0" ,
    }
]


return (
    <div className="page_content service_details_page">
            <DistributorInfo title={t("sellerInfo")} data={[
                { key: t("name"), value: salesData?.first_name + " " + salesData?.last_name || "-" },
                { key: t("email"), value: salesData?.email || "-" },
                { key: t("address"), value: salesData?.address_line && JSON.parse(salesData.address_line).city + ", " + JSON.parse(salesData.address_line).region + ", " + JSON.parse(salesData.address_line).street || "-" },
                { key: t("phone"), value: salesData?.mobile || "-" },
                { key: t("createdAt"), value: moment(salesData?.created_at).format("DD/MM/YYYY") || "-" },
            ]} />
          <ListHeader customClass="row noMargin">
                <h2>{t("sellerStats")}</h2>
                <Button text={t("editSeller")} 
                onClick={() => navigate(`/sales/create-sales-executive/${id}`)}
                 />

            </ListHeader>
        <CommonHighlights data={highlights} />

        <div className="table-wrapper">
            <ListHeader customClass="row">
                <h2>{t("listOfBookings")}</h2>
            </ListHeader>
            <BookingsTable
                tableHeaders={tableHeaders}
                data={salesData?.bookings}
                noDataMessage={t("No_bookings_found")}
                setRefetchData={setRefetch}
            />
        </div>
   
    </div>
);
}

export default SalesDetails;