import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setBreadCrumbsData } from "../../../store/redux/breadCrumbsData";
import CreateSalesForm from "../../../modules/sales/components/CreateSalesForm";

const CreateSales = () => {

    const { t } = useTranslation()
    const dispatch = useDispatch()
    dispatch(setBreadCrumbsData({
        links: [ { label: t("create_sales"), path: "/sales/create-sales-executive" }],
        page_title: t("create_sales"),
    }))

    return (
        <div>
            <CreateSalesForm />
        </div>
    );
}

export default CreateSales;