import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { sideMenuOpenIcon, AboutIcon, ArticlesIcons, CategoriesIcon, ClubRegistrationIcons, applicationsIcon, ContactLinksIcon, ContactUsIcon, ContactUsListIcon, CourtsIcon, NOCRegistrationIcons, NewsIcon, NewsletterListIcon, PlayersIcon, PlayersListIcon, SponsorsIcon, TeamsListIcon, TermsAndCondIcon, TournamentIcon, TournamentRequestsIcon, TournamentsIcon, UsersIcon, homeIcon, logo_flag_only, logo_label_only, settingsIcon, websiteIcon, CreateTournamentsIcon, ListTournamentsIcon, CalenderTournamentsIcon, Terms_Conditions, serviceIcon, productIcon, distIcon, createIcon, listIcon, Sales, Deal } from "../../../config/variables";
import ActiveSideMenuAccordionContextProvider from "../../../store/context/activeSideMenuAccordionContext";
import TogglerNavLink from "./TogglerNavLink";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const SideMenu = ({ openMenu, setOpenMenu } : { openMenu?: boolean, setOpenMenu?: React.Dispatch<React.SetStateAction<boolean>>}) => {
  const { t } = useTranslation()
  const { pathname } = useLocation();
  const [expanded, setExpanded] = useState<boolean>(true);
  const [keepExpanded, setKeepExpanded] = useState<boolean>(true);
  const [hover, setHover] = useState<boolean>(false);
  const el = useRef<HTMLDivElement>(null);
  const tl = useRef<gsap.core.Timeline>(gsap.timeline());
  const tl2 = useRef<gsap.core.Timeline>(gsap.timeline());
  const [reAnimate, setReAnimate] = useState<boolean>(false);
  const navigate = useNavigate();

  const isDesktopOrBigger = useMediaQuery({ query: '(min-width: 1024px)' });


  useEffect(() => {
    let ctx = gsap.context(() => {
      tl.current = gsap.timeline();
    
        if (! isDesktopOrBigger) {
          tl.current.fromTo(
            ".label",
            {
              autoAlpha: 0,
            },
            {
              autoAlpha: 1,
              ease: "power3.inOut",
              duration: 0.3,
            },
            0.1
          );
        }
       
        if (isDesktopOrBigger) {
          tl.current
             .fromTo(
                el.current,
                {
                  width: "4.5rem",
                },
                {
                  width: isDesktopOrBigger? "20rem" : "100%",
                  ease: "power3.inOut",
                  duration: 0.5,
                })
            .fromTo(
              ".logo_expand_container .logo_container h4",
              {
                width: 0,
                duration: 0.5,
              },
              {
                width: "14rem",
                ease: "power3.inOut",
                duration: 0.5,
              },
              0.1
            )
            .fromTo(
              ".logo_expand_container .expand_btn",
              {
                marginInlineEnd: "1rem",
                duration: 0.5,
              },
              {
                marginInlineEnd: 0,
                ease: "power3.inOut",
                duration: 0.5,
              },
              0.1
            )
            .fromTo(
              ".logo_expand_container .logo_container h4",
              {
                autoAlpha: 0,
                duration: 0.7,
              },
              {
                autoAlpha: 1,
                ease: "power3.inOut",
                duration: 0.7,
              },
              0.2
            );
        }
      });

    return () => {
      ctx.revert();
    };
  }, [isDesktopOrBigger]);

  useEffect(() => {
    tl2.current = gsap.timeline({ paused: true });
    tl2.current
      .fromTo(
        ".sidemenu_wrapper .nav_links .toggler .collapsed_items.expanded_items",
        { height: 0 },
        {
          height: "auto",
          ease: "power3.inOut",
          duration: 0.35,
        }
      )
      .fromTo(
        ".sidemenu_wrapper .nav_links .toggler .collapsed_items.expanded_items",
        { autoAlpha: 0 },
        {
          autoAlpha: 1,
          ease: "power3.inOut",
          duration: 0.3,
        },
        0.15
      );
    reAnimate ? tl2.current?.play() : tl2.current?.reverse();
  }, [reAnimate]);



  const serviceProviderLinks = {
    header: t("links.services"),
    baseRoute: "/services",
    headerIcon: [serviceIcon],
    nestedLinks: [
      {
        label: t("links.services"), link: "/services", icon: [listIcon],
      },
      {
        label: t("links.create_service"), link: "/services/create-service", icon: [createIcon],
      },
    ]
  }
  const productLinks = {
    header: t("links.products"),
    baseRoute: "/products",
    headerIcon: [productIcon],
    nestedLinks: [
      {
        label: t("links.products"), link: "/products", icon: [listIcon],
      },
      {
        label: t("links.create_product"), link: "/products/create-product", icon: [createIcon],
      },
    ]
  }
  const distributorsLinks = {
    header: t("links.distributors"),
    baseRoute: "/distributors",
    headerIcon: [distIcon],
    nestedLinks: [
      {
        label: t("links.distributors"), link: "/distributors", icon: [listIcon],
      },
      {
        label: t("links.create_distributor"), link: "/distributors/create-distributor", icon: [createIcon],
      },
    ]
  }

  const salesLinks = {
    header: t("sales"),
    baseRoute: "/sales",
    headerIcon: [Sales],
    nestedLinks: [
      {
        label: t("sales"), link: "/sales", icon: [listIcon],
      },
      {
        label: t("create_sales"), link: "/sales/create-sales-executive", icon: [createIcon],
      },
    ]
  }
  const applicationsLinks = {
    header: t("links.applications"),
    baseRoute: "/applications",
    headerIcon: [applicationsIcon],
    nestedLinks: [
      {
        label: t("links.applications"), link: "/applications", icon: [listIcon],
      },
      {
        label: t("find_deal"), link: "/applications/create/find-deal", icon: [Deal],
      },
    ]
  }

  return (
    <div className={`sidemenu_wrapper ${!isDesktopOrBigger && (openMenu ? "open" : "close")}  ${(pathname == "/auth/login" || pathname == "/auth/forgot-password") && "hide_sidebar"}`} ref={el}
      onMouseEnter={() => { if (!keepExpanded && isDesktopOrBigger) { tl.current.play(); setHover(true); setReAnimate(true) } }}
      onMouseLeave={() => { if (!keepExpanded && isDesktopOrBigger) { tl.current.reverse(); setHover(false); setReAnimate(false) } }}
    >
      <div className="logo_expand_container">
        <div className="logo_container pointer">
        <h4 style={{color:"white"}} onClick={()=>
          { const contentContainer = document.querySelector(".layout_content");
            contentContainer?.scrollTo({ top: 0, behavior: "smooth" });
            navigate("/")}}>Financial Services</h4>
        </div>
        <div className={`expand_btn label ${keepExpanded && "keep_expanded"}`}
          onClick={() => {
            isDesktopOrBigger ? setExpanded(!expanded) : setOpenMenu && setOpenMenu(false);
            isDesktopOrBigger && setKeepExpanded(!keepExpanded);

          }}
        >
          {sideMenuOpenIcon}
        </div>

      </div>
      <ul className="nav_links">
        <ActiveSideMenuAccordionContextProvider>
          <TogglerNavLink key={"serviceProviderLinks"} keyName="serviceProviderLinks" links={serviceProviderLinks} setMenu={setOpenMenu}/>
          <TogglerNavLink key={"productLinks"} keyName="productLinks" links={productLinks} setMenu={setOpenMenu}/>
          <TogglerNavLink key={"distributorsLinks"} keyName="distributorsLinks" links={distributorsLinks} setMenu={setOpenMenu}/>
          <TogglerNavLink key={"salesLinks"} keyName="salesLinks" links={salesLinks} setMenu={setOpenMenu}/>
          <TogglerNavLink key={"applicationsLinks"} keyName="appLinks" links={applicationsLinks} setMenu={setOpenMenu}/>
        </ActiveSideMenuAccordionContextProvider>
      </ul>
    </div>
  );

};

export default SideMenu;