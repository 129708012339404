import { useDispatch } from "react-redux";
import { setBreadCrumbsData } from "../../../store/redux/breadCrumbsData";
import { useTranslation } from "react-i18next";
import CreateServiceProviderForm from "../../../modules/serviceProvider/components/CreateServiceProviderForm";

const CreateService = () => {

    const {t}=useTranslation()
    const dispatch=useDispatch()
    dispatch(setBreadCrumbsData({
        links: [{label: t("create_service"),path:"/services/create-service"}],
		page_title: t("create_service"),
    }))
    
    return (
        <div>
            <CreateServiceProviderForm/>
        </div>
    );
}

export default CreateService;