import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setBreadCrumbsData } from "../../../store/redux/breadCrumbsData";
import TableSkeleton from "../../../components/loaders/TableSkeleton";
import { useQuery } from "@tanstack/react-query";
import { authContext } from "../../../store/context/authContext";
import { useContext, useEffect, useState, useTransition } from "react";
import { ISales } from "../../../modules/sales/types/interfaces";
import { generalGet } from "../../../API/api";
import ListHeader from "../../../components/ListHeader";
import SearchInputField from "../../../components/SearchInputField";
import UsersTableContainer from "../../../modules/sales/components/UsersTableContainer";

const SalesList = () => {

    const { t, i18n } = useTranslation()
    const { catchError } = useContext(authContext)
    const dispatch = useDispatch()
    const [isPending, startTransition] = useTransition()
    const [refetch, setRefetch] = useState(false)
    const [users, setUsers] = useState<ISales[]>([])
    const [shownList, setShownList] = useState<ISales[]>([])
    const [searchInput, setSearchInput] = useState("")

    dispatch(setBreadCrumbsData({
        links: [ { label: t("sales"), path: "/sales" }],
        page_title: t("sales"),
    }))
    const { data, isSuccess, isLoading, error } = useQuery({
        queryKey: ["Sales Executives", refetch, i18n],
        queryFn: () => generalGet("/admin/users?role_name=mynm-sales"),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        const reqData = data?.data.data
        isSuccess && setUsers(reqData.map((item: ISales) => (item)))
        console.log(reqData, "reqDataSales");
        
    }, [isSuccess, data])

    useEffect(() => {
        error && catchError(error)
    }, [error])

    const usersTableHeaders = [
        { label: t("first_name") },
        { label: t("last_name") },
        { label: t("email") },
        { label: t("phone") },
        { label: t("role") },
        // { label: t("status"), customClass: "status_col" },
        { label: t("actions"), customClass: "actions_col" },
    ];

    if (isLoading) return <TableSkeleton columns={6} withoutButton />

    return (
        <div className="distributors-page-container">
            <ListHeader>
                <div className="filter_container">
                    <SearchInputField placeholder={t("search_by_id_Name")} setSearchInput={setSearchInput} searchInput={searchInput} />
                </div>
            </ListHeader>
            <UsersTableContainer
                tableHeaders={usersTableHeaders}
                data={users}
                noDataMessage={t("No_users_found")}
                border
                setRefetchData={setRefetch}
            />
        </div>
    );
}

export default SalesList;