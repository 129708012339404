import { IServiceInfo } from "../types/interfaces";


const CommonHighlights = ({data}:{data:IServiceInfo[]}) => {

	return (
		<div className="common-highlight">
			<div className="cards-container">
				{data?.map((item: IServiceInfo, index: number) => (
					<div className="card" key={index}>
						<h6>{item.title}</h6>
						<p>{item.number}</p>
					</div>
				))}
			</div>
		</div>
	);
}

export default CommonHighlights;