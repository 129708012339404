import { useEffect } from "react"
import { IWorkSector } from "../../modules/products/types/interfaces"

interface IProps {
  label: {
    label: string
    value: string
  }
  value: boolean
  formik?: any
  checked: string[],
  name: string,
  workSectors: IWorkSector[]
}

const Checkbox = ({ label, value, formik, checked, name, workSectors }: IProps) => {
  const handleChange = () => {
    // Individual checkbox logic
    if (checked && checked.some((item) => item === label.value)) {
      formik?.setFieldValue(name, checked?.filter((item) => item !== label.value));
    } else {
      formik?.setFieldValue(name, [...checked, label.value]);

    }
  };

  return (
    <label>
      <input
        id={`${name}`}
        type="checkbox"
        checked={value}
        onChange={handleChange}
      />
      {label.label}
    </label>
  );
};

export default Checkbox