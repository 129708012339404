import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ICarData, IWorkSector } from "../../../products/types/interfaces";
import { TOptions } from "../../../../types/types";
import { generalGet } from "../../../../API/api";
import CarDetailsForm from "./CarDetailsForm";
import { scrollToError } from "../../../../utils/HelperFunctions";
import Button from "../../../../components/buttons/Button";
import CustomerDetailsForm from "./CustomerDetailsForm";
import { toast } from "react-toastify";
import { IService } from "../../../serviceProvider/types/interfaces";
import { ISearchProduct } from "../../types/interface";
import { useDispatch, useSelector } from "react-redux";
import { setSearchData, setSearchResult, setSelectedProducts } from "../../store/redux";
import FormSkeleton from "../../../../components/loaders/FormSkeleton";
import { FixedSearchData } from "../../../../config/FixedData";

interface IProps {
    setActiveStep: React.Dispatch<React.SetStateAction<number>>
}
const SearchForDeal = ({ setActiveStep }: IProps) => {
    const [carData, setCarData] = useState<ICarData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [carMakeOptions, setCarMakeOptions] = useState<TOptions[]>([])
    const [workSectors, setWorkSectors] = useState<TOptions[]>([])
    const [services, setServices] = useState<TOptions[]>([])
    const { t, i18n } = useTranslation()
    const formRef = useRef(null)
    const dispatch = useDispatch()
    const { searchData } = useSelector((store: any) => store.findDeal)

    const validationSchema = Yup.object().shape({
        vehicle_model_id: Yup.string().required(t('required')),
        variant_id: Yup.string().required(t('required')),
        work_sector_id: Yup.string().required(t('required')),
        nationality: Yup.string().required(t('required')),
        salary_transfer_to: Yup.string().required(t('required')),
        price: Yup.number()
            .typeError(t('must_be_a_number'))
            .required(t('required'))
            .min(0, t('must_great')),
        age: Yup.number()
            .typeError(t('must_be_a_number'))
            .required(t('required'))
            .min(18, t('must_great')),
        salary: Yup.number()
            .typeError(t('must_be_a_number'))
            .required(t('required'))
            .min(1000, t('must_great')),
        service_period: Yup.number()
            .typeError(t('must_be_a_number'))
            .required(t('required'))
            .min(1, t('must_great')),
        finance_duration: Yup.number()
            .typeError(t('must_be_a_number'))
            .required(t('required'))
            .min(1, t('must_great')),
        down_payment: Yup.number()
            .typeError(t('must_be_a_number'))
            .min(0, t('must_great')),
        balloon_percentage: Yup.number()
            .typeError(t('must_be_a_number'))
            .min(0, t('must_great')),
        insurance_fee: Yup.number()
            .typeError(t('must_be_a_number'))
            .required(t('required'))
            .min(0, t('must_great')),
        admin_fee: Yup.number()
            .typeError(t('must_be_a_number'))
            .required(t('required'))
            .min(0, t('must_great')),
        profit_rate: Yup.number()
            .typeError(t('must_be_a_number'))
            .required(t('required'))
            .min(0, t('must_great')),
    });

    const initialValues: ISearchProduct = searchData || { ...FixedSearchData }

    const { data, isSuccess, isLoading } = useQuery({
        queryKey: ["carData"],
        queryFn: () => generalGet(`/admin/makes?with_models=1&with_variants=1`),
        refetchOnWindowFocus: false
    });

    const { data: workSectorsData, isSuccess: workSectorsSuccess, isLoading: workSectorsLoading } = useQuery({
        queryKey: ["work sectors", i18n.language],
        queryFn: () => generalGet(`/work-sectors`),
        refetchOnWindowFocus: false
    });

    const { data: servicesData, isSuccess: servicesSuccess, isLoading: servicesLoading } = useQuery({
        queryKey: ["service providers", i18n.language],
        queryFn: () => generalGet(`/service-providers`),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        const reqData = data?.data?.data
        setCarData(reqData)
        isSuccess && setCarMakeOptions(reqData?.map((car: ICarData) => {
            return { label: car.name, value: car.id }
        }))
    }, [isSuccess, data])

    useEffect(() => {
        const reqData = workSectorsData?.data.data
        workSectorsSuccess && setWorkSectors([...reqData?.map((sector: IWorkSector) => {
            return { label: sector.name, value: sector.id.toString() }
        })])
    }, [workSectorsSuccess, workSectorsData])

    useEffect(() => {
        const reqData = servicesData?.data.data
        servicesSuccess && setServices([...reqData?.map((service: IService | any) => {
            return { label: service.name[i18n.language], value: service.id }
        })])

    }, [servicesSuccess, servicesData, i18n])

    if (workSectorsLoading || servicesLoading || isLoading) return <div>
        <FormSkeleton featuredSections={1} noImage />
    </div>

    return (
        <div ref={formRef} style={{ scrollMarginTop: "12rem" }}>
            <Formik
                enableReinitialize
                validateOnMount
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={(values) => {
                    setLoading(true)
                    dispatch(setSelectedProducts([]))
                    dispatch(setSearchData(values))
                    const data = new URLSearchParams(values as {}).toString()
                    generalGet(`/search/products?${data}`)
                        .then(res => {
                            const results: any = []
                            res.data.data.map((item: any) => {
                                item.yearly_profit_rates.map((rate: any, index: number) => {
                                    results.push({
                                        ...item, yearly_profit_rates: null, ...rate, duration_to: index + 1,
                                        total_price: item.total_price[index],
                                        monthly_installments: item.total_price.monthly_installments[index],
                                    })
                                })

                            })
                            dispatch(setSearchResult(results))
                            const contentContainer = document.querySelector(".layout_content");
                            contentContainer?.scrollTo({ top: 0, behavior: "smooth" });
                            setActiveStep(1)
                        })
                        .catch(error => {
                            toast.error(t("somethingWrong"))
                            setLoading(false)
                        })
                }}
            >
                {(formik) => (
                    <Form >
                        <CarDetailsForm
                            formik={formik}
                            carData={carData}
                            carMakeOptions={carMakeOptions}
                        />
                        <CustomerDetailsForm formik={formik} workSectors={workSectors} services={services} />
                        <div className="form_button reverse">
                            <Button
                                loading={loading}
                                disabled={loading}
                                onClick={() => { scrollToError(!formik.isValid, formRef) }}
                                text={t("submit")} type={'submit'} ></Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default SearchForDeal;