import { t } from "i18next"

export const Nationalities = {
	en: ["saudi", "non-Saudi"],
	ar: ["سعودي", "اجنبي"],
}
export const SalaryTo = ["payroll", "non-payroll"]
export const Gender = {
	en: ["male", "female"],
	ar: ["ذكر", "انثي"],
}
export const FixedSearchData = {
	vehicle_model_id: "",
	variant_id: "",
	price: "",
	work_sector_id: "",
	nationality: "",
	salary_transfer_to: "",
	age: "",
	salary: "",
	down_payment: "",
	finance_duration: "",
	service_period: "",
	balloon_percentage: "",
	profit_rate: "",
	admin_fee: "",
	insurance_fee: "",
	car_make: "",
}
export const days = Array.from({ length: 31 }, (_, i) => i + 1)
export const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
]
export const currentYear = new Date().getFullYear()
export const years = Array.from({ length: 65 }, (_, i) => currentYear - 80 + i)
