import { useContext, useEffect, useState } from "react";
import ModalContainer from "../../../components/ModalContainer";
import Button from "../../../components/buttons/Button";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setBreadCrumbsData } from "../../../store/redux/breadCrumbsData";
import { useNavigate, useParams } from "react-router-dom";
import { generalGet } from "../../../API/api";
import { useQuery } from "@tanstack/react-query";
import { authContext } from "../../../store/context/authContext";
import InitialLoader from "../../../components/loaders/InitialLoader";
import { approvedStepIcon, pendingStepIcon, rejectedStepIcon } from "../../../config/variables";

const ApplicationDetails = () => {

    const { catchError } = useContext(authContext)
    const [openModel, setOpenModel] = useState<boolean | number>(true);
    const { t, i18n } = useTranslation();
    const { id } = useParams()
    const navigate = useNavigate();
    const [application, setApplication] = useState<any>()
    const [status, setStatus] = useState<string>("")

    const dispatch = useDispatch()
    dispatch(setBreadCrumbsData({
        links: [{ label: t("links.applications"), path: `/applications/${id}` }],
        page_title: t("application") + ` #${id}`,
    }))

    const { data, isSuccess, isLoading, error } = useQuery({
        queryKey: ["Application Details", id, i18n],
        queryFn: () => generalGet(`/applications/${id}`),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        error && catchError(error)
    }, [error])

    useEffect(() => {
        const reqData = data?.data?.data
        isSuccess &&
            setApplication(reqData)
        setStatus(reqData?.status)
    }, [isSuccess, data])


    if (isLoading) return <div className="centered-flex" style={{ minHeight: "70vh" }}><InitialLoader pageLoader /></div>


    return (
        <div>
            {openModel &&
                <ModalContainer small>
                    <div className="common-modal status">
                        <h4>{`${t("application")} #${id} ${status}`}</h4>
                        <div className="statusIcon">

                            {status?.toLowerCase() === "approved" && approvedStepIcon}
                            {status?.toLowerCase() === "rejected" && rejectedStepIcon}
                            {status?.toLowerCase() === "in progress" && pendingStepIcon}

                        </div>
                        <p>{application?.current_step?.message} </p>
                        <div className="buttons">
                            <Button
                                onClick={() => {
                                    setOpenModel(false)
                                    window.scroll(0, 0);
                                    navigate(`/applications`)
                                }
                                }
                            >
                                <span className="bold">{t("close")}</span>
                            </Button>

                        </div>
                    </div>
                </ModalContainer>
            }
        </div>
    );
}

export default ApplicationDetails;