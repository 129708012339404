import axios from "axios"
import { API_URL } from "./APIs"
import Cookies from "js-cookie"
import i18next from "i18next"


export const axiosInstance = axios.create({
	baseURL: API_URL,
	headers: {
		Accept: "application/json",
		'Accept-Language': i18next.language || 'en',
	},
})

axiosInstance.interceptors.request.use(
	(config) => {
		const token = Cookies.get("token")
		if (token) {
			config.headers.authorization = `Bearer ${token}`
		}
		config.headers['Accept-Language']= i18next?.language || "en"
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)
