import { useContext, useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import "react-phone-number-input/style.css";
import { toast } from "react-toastify";
import FieldWrapper from "../../../components/formInputs/FieldWrapper";
import Button from "../../../components/buttons/Button";
import { generalCreate, generalGet } from "../../../API/api";
import { authContext } from "../../../store/context/authContext";
import { useTranslation } from "react-i18next";
import DateRangeField from "../../../components/formInputs/DateRangeField";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import SectionHeader from "../../../components/SectionHeader";
import { off } from "process";
import { IOffer } from "../types/interfaces";
import moment from "moment";
import FormSkeleton from "../../../components/loaders/FormSkeleton";
import { scrollToError } from "../../../utils/HelperFunctions";
import { isAfter, isToday, isValid, parse } from "date-fns";
import { FormControlLabel, Switch } from "@mui/material";

const CreateOfferForm = () => {
    const navigate = useNavigate()
    const { id, offerId } = useParams()
    const formRef = useRef(null)
    const [loading, setLoading] = useState(false);
    const [offer, setOffer] = useState<IOffer>();
    const [profitRates, setProfitRates] = useState([]);
    const [refetch, setRefetch] = useState(false)
    const { catchError } = useContext(authContext);
    const { t } = useTranslation()
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection"
    });

    const { data, isSuccess, isLoading } = useQuery({
        queryKey: ["product", id],
        queryFn: () => generalGet(`/products/${id}`),
        enabled: !!id,
    });

    const { data: offerData, isSuccess: offerIsSuccess, isLoading: offerIsLoading } = useQuery({
        queryKey: ["offer", offerId, refetch],
        queryFn: () => generalGet(`/offers/${offerId}`),
        enabled: !!offerId,
    });

    useEffect(() => {
        isSuccess && setProfitRates(data.data.data.profit_rate)
    }, [isSuccess, data])

    useEffect(() => {
        offerIsSuccess && setOffer(offerData.data.data)
    }, [offerIsSuccess, offerData])


    const validationSchema = Yup.object({
        start_date: Yup.string().required(t("required")),
        end_date: Yup.string().required(t("required"))
            .test('greaterThanOrEqualCurrentDate', t("date_must"), function (value) {
                const currentDate = new Date();
                if (!value) {
                    return true;
                }
                const parsedEnteredDate = parse(value, 'yyyy-MM-dd', new Date());
                if (!isValid(parsedEnteredDate)) {
                    return false;
                }
                return isAfter(parsedEnteredDate, currentDate) || isToday(parsedEnteredDate);
            })
        ,
        admin_fee: Yup.number().typeError(t("must_no")).min(0, t("must_great")).max(100, t("must_min"))
            .test("profit-required", t("required"), function (value) {
                const { profit_rate } = this.parent;
                if (!profit_rate[0]) {
                    return !!value;
                }
                return true;
            }),
        profit_rate: Yup.array()
            .of(Yup.number().min(0, t("must_great")).max(100, t("must_min")).nullable())
    });

    if (offerIsLoading || isLoading) return <FormSkeleton featuredSections={1} offer />

    return (
        <div className="create_user_form modal_content" ref={formRef}>
            <Formik
                enableReinitialize
                validateOnMount
                validationSchema={validationSchema}
                initialValues={{
                    start_date: offer?.start_date ? moment(offer?.start_date).format('YYYY-MM-DD') : "",
                    end_date: offer?.end_date ? moment(offer?.end_date).format('YYYY-MM-DD') : "",
                    admin_fee: offer?.admin_fee || "",
                    profit_rate: offer?.profit_rate ?
                        profitRates.length == offer?.profit_rate.length ? offer?.profit_rate :
                            [...offer.profit_rate, ...new Array(profitRates.length).fill(null)].slice(0, profitRates.length)
                        : new Array(profitRates.length).fill(null),
                    is_active: offer?.is_active || false
                }}
                onSubmit={(values) => {
                    setLoading(true);
                    if (offerId) {
                        generalCreate({
                            route: `/admin/offers/${offerId}`,
                            values: { ...values, product_id: id, _method: "PUT" },
                        }).then((res) => {
                            setLoading(false)
                            toast.success(`${t("offer")} ${t("updated")} ${t("successfully")}`)
                            setRefetch(true)
                            navigate(`/products/${id}`)
                        }
                        ).catch(error => {
                            catchError(error, setLoading)
                        })
                        return
                    }
                    generalCreate({
                        route: "/admin/offers",
                        values: { ...values, product_id: id },
                    }).then((res) => {
                        setLoading(false)
                        toast.success(`${t("offer")} ${t("created")} ${t("successfully")}`)
                        setRefetch(true)
                        navigate(`/products/${id}`)
                    }
                    ).catch(error => {
                        catchError(error, setLoading)
                    })
                }}
            >
                {(formik) => (
                    <>
                        <Form>
                            <div className="form_inputs">
                                <SectionHeader title={t("offerDetails")}>
                                    <div className="inputs_group">
                                        <FieldWrapper title={t("offerDuration")}>
                                            <DateRangeField
                                                formik={formik}
                                                selectionRange={selectionRange}
                                                setSelectionRange={setSelectionRange}
                                                endValue={formik.values.end_date}
                                                startValue={formik.values.start_date}
                                            />
                                        </FieldWrapper>
                                        <FieldWrapper noPadding />
                                    </div>
                                    <div className="inputs_group">
                                        <FieldWrapper
                                            title={t("adminFee")}
                                            inputName={`admin_fee`}
                                            inputPlaceholder={"15%"}
                                            inputError={formik.errors.admin_fee as string}
                                            inputTouched={formik.touched.admin_fee as boolean}
                                            input
                                            border
                                            type="number"
                                            showUnit
                                            unit="%"
                                        />
                                        <FieldWrapper noPadding />
                                        <FieldWrapper noPadding />
                                    </div>
                                    <div className="inputs_group">
                                        <FieldWrapper title={t("offer_activation")}>
                                            <Switch size="medium"
                                                checked={formik?.values?.is_active}
                                                onChange={(e) => {
                                                    formik.setFieldValue("is_active", e?.target?.checked)
                                                }}
                                            />
                                        </FieldWrapper>
                                    </div>
                                </SectionHeader>
                                < SectionHeader title={t("OfferProfitRates")} customStyle="last">
                                    <div className="inputs_group">
                                        {profitRates.map((item: number, index: number) => (
                                            <FieldWrapper
                                                key={index}
                                                title={(index + 1) + " " + t("year")}
                                                inputName={`profit_rate[${index}]`}
                                                inputPlaceholder={"25%"}
                                                inputError={formik.errors.profit_rate && formik.errors.profit_rate[index]! as string}
                                                inputTouched={
                                                    Array.isArray(formik.touched.profit_rate) &&
                                                    !!formik.touched.profit_rate[index]
                                                } input
                                                border
                                                type="number"
                                                showUnit
                                                unit="%"
                                            />
                                        ))}
                                        {profitRates?.length < 3 &&
                                            <>
                                                <FieldWrapper noPadding />
                                                <FieldWrapper noPadding />
                                            </>
                                        }
                                    </div>
                                </SectionHeader>
                            </div>
                            <div className="form_button reverse">
                                <Button
                                    type={loading ? "button" : "submit"}
                                    loading={loading}
                                    onClick={() => { scrollToError(!formik.isValid, formRef) }}
                                >
                                    <span className="bold">{t("save")}</span>
                                </Button>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>

        </div>
    );
};

export default CreateOfferForm;
