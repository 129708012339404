import { useDispatch } from "react-redux";
import { setBreadCrumbsData } from "../../../store/redux/breadCrumbsData";
import { useEffect, useState, useTransition } from "react";
import { useQuery } from "@tanstack/react-query";
import { generalGet } from "../../../API/api";
import TableSkeleton from "../../../components/loaders/TableSkeleton";
import ListHeader from "../../../components/ListHeader";
import SearchInputField from "../../../components/SearchInputField";
import { searchFilterLogic } from "../../../utils/HelperFunctions";
import { useTranslation } from "react-i18next";
import { IProductData } from "../../../modules/products/types/interfaces";
import ProductsTableContainer from "../../../modules/products/components/ProductsTableContainer";
const Products = () => {

    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    dispatch(setBreadCrumbsData({
        links: [{ label: t("products"), path: "/products" }],
        page_title: t("products"),
    }))
    const [isPending, startTransition] = useTransition()
    const [selectedOption, setSelectedOption] = useState(null);

    const [searchInput, setSearchInput] = useState("")
    const [refetch, setRefetch] = useState(false)
    const [products, setProducts] = useState<IProductData[]>([])
    const [shownList, setShownList] = useState<IProductData[]>([])
    const { data, isSuccess, isLoading } = useQuery({
        queryKey: ["products", refetch],
        queryFn: () => generalGet("/products"),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        const reqData = data?.data.data
        isSuccess && setProducts(reqData.map((item: IProductData) => {
            const productName = item.service_provider.name[i18n.language as keyof typeof item.service_provider.name];
            return { ...item, productName };
        }))
        // isSuccess && setProducts(reqData)
    }, [isSuccess, data])

    const tableHeaders = [
        // { label: t("id"), customClass: "pro_id_col" },
        // { label: t("logo"), customClass: "pro_logo_col" },
        { label: t("serviceProvider"), customClass: "registration_date_col text-center" },
        { label: t("financialDuration"),customClass: "text-center" },
        { label: t("profitRate"),customClass: "text-center"},
        { label: t("adminFees"), customClass: "text-center" },
        // { label: t("offers"), customClass: "status_col" },
        { label: t("actions"), customClass: "actions_col" }
    ];

    useEffect(() => {
        setSelectedOption(null)
        setShownList(products)
        if (searchInput) {
            const results = searchFilterLogic({ searchInput: searchInput, listOfData: products, keys: ["id", "productName"] });
            startTransition(() => {
                setShownList(results)
            })
        }
    }, [products, searchInput])

    if (isLoading) return <TableSkeleton columns={6} withoutButton />
    
    return (
        <div className="services-page-container">
            <ListHeader>
                <div className="filter_container">
                    <SearchInputField placeholder={t("search_by_id_Name")} setSearchInput={setSearchInput} />
                </div>
            </ListHeader>
            <ProductsTableContainer
                tableHeaders={tableHeaders}
                data={shownList}
                noDataMessage={t("No_products_found")}
                setRefetchData={setRefetch}
            />
        </div>
    );
}

export default Products;


