import { useDispatch } from "react-redux";
import { setBreadCrumbsData } from "../../../store/redux/breadCrumbsData";
import { useTranslation } from "react-i18next";
import CreateDistributorForm from "../../../modules/distributors/components/CreateDistributorForm";

const CreateDistributor = () => {

    const {t}=useTranslation()
    const dispatch=useDispatch()
    dispatch(setBreadCrumbsData({
        links: [{label: t("create_distributor"),path:"/distributors/create-distributor"}],
		page_title: t("create_distributor"),
    }))
    
    return (
        <div>
            <CreateDistributorForm/>
        </div>
    );
}

export default CreateDistributor;