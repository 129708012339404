
interface IProps {
    activeStep: number;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
    steps: string[]
}
const FormSteps = ({ activeStep, setActiveStep, steps }: IProps) => {

    return (
        <ul className="steps_header">
            {steps.map((step, index) => (
                <li
                    key={index}
                    className={`${activeStep == index && 'active'} 
                        ${activeStep < index && 'normal-cursor '}
                        `}
                    onClick={() => { activeStep > index && setActiveStep(index) }}
                >
                    <span>{activeStep > index ? <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.0156 0.984375C11.3203 1.26562 11.3203 1.75781 11.0156 2.03906L5.01562 8.03906C4.73438 8.34375 4.24219 8.34375 3.96094 8.03906L0.960938 5.03906C0.65625 4.75781 0.65625 4.26562 0.960938 3.98438C1.24219 3.67969 1.73438 3.67969 2.01562 3.98438L4.47656 6.44531L9.96094 0.984375C10.2422 0.679688 10.7344 0.679688 11.0156 0.984375Z" fill="black" />
                    </svg>
                        : "0" + (index + 1)}</span>
                    <span>{step}</span>
                </li>
            ))}
        </ul>
    );
}

export default FormSteps;