import { useState } from 'react';
import ModalContainer from '../../../components/ModalContainer';
import DeleteModal from '../../../components/DeleteModal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const UsersTableContainer = ({ tableHeaders, data, noDataMessage, setRefetchData, border, setOpenModel,
    setUserId }: any) => {

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const { t } = useTranslation()
    const { roles } = useSelector((store: any) => store.rolesData)

    return (
        <div className={`table_container ${border && "border"}`}>
            <div className="table_header">
                {tableHeaders?.map((header: any, index: number) => (
                    <span className={`head ${header.customClass}`} key={index}>{header.label}</span>
                ))}
            </div>
            <div className={`table_data ${(!data || data?.length == 0) && "no_data"}`}>
                {data?.length > 0 ?
                    <>
                        {data?.map((item: any, index: number) => (
                            <div className="item  has_logo" key={index}>
                                <div className="column " >{item?.first_name || "-"}</div>
                                <div className="column " >{item?.last_name || "-"}</div>
                                <div className="column " >{item?.email || "-"}</div>
                                <div className="column " >{item?.mobile || "-"}</div>
                                {/* <div className="column " >{item?.city || "-"}</div> */}
                                <div className="column " >{item?.profession || "-"}</div>
                                <div className="column " >{roles?.find((role: any) => role.value == item?.role_id)?.label || "-"}</div>
                                <div className={`column status_col clickable capitalize`} onClick={(e) => { e.stopPropagation(); setDeleteModalOpen(item.id) }
                                }
                                ><div className={`${item?.status == 2 ? "active" : ""}`}>{`${item?.status == 2 ? t("active") : t("disabled")}`}</div></div>

                                <div className="column actions actions_col">
                                    <div className="action_btn edit" onClick={(e) => { e.stopPropagation(); setUserId(item.id); setOpenModel(2); }}>
                                        <svg width="18" height="20" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="m11.25 6c.398 0 .75.352.75.75 0 .414-.336.75-.75.75-1.505 0-7.75 0-7.75 0v12h17v-8.749c0-.414.336-.75.75-.75s.75.336.75.75v9.249c0 .621-.522 1-1 1h-18c-.48 0-1-.379-1-1v-13c0-.481.38-1 1-1zm1.521 9.689 9.012-9.012c.133-.133.217-.329.217-.532 0-.179-.065-.363-.218-.515l-2.423-2.415c-.143-.143-.333-.215-.522-.215s-.378.072-.523.215l-9.027 8.996c-.442 1.371-1.158 3.586-1.264 3.952-.126.433.198.834.572.834.41 0 .696-.099 4.176-1.308zm-2.258-2.392 1.17 1.171c-.704.232-1.274.418-1.729.566zm.968-1.154 7.356-7.331 1.347 1.342-7.346 7.347z" fillRule="nonzero" /></svg>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                    :
                    <h6>{noDataMessage}</h6>
                }
            </div>
            {deleteModalOpen && (
                <ModalContainer small>
                    <DeleteModal
                        id={deleteModalOpen}
                        setModal={setDeleteModalOpen}
                        setRefetchData={setRefetchData}
                        route={`/users/${deleteModalOpen}/toggle-status`}
                        successMsg={`User status changed successfully`}
                        warningMsg={`Are you sure you want to change the status of that user?`}
                        post />
                </ModalContainer>
            )}
        </div>
    );
}

export default UsersTableContainer;