import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { ICarData } from "../../types/interfaces";
import FieldWrapper from "../../../../components/formInputs/FieldWrapper";
import { customStyles } from "../../../../utils/SelectStyles";
import { generalGet } from "../../../../API/api";
import RadioInput from "../../../../components/formInputs/RadioInput";
import { TOptions } from "../../../../types/types";

const salaryTo = ["payroll", "non-payroll"]
const BalloonsInputs = ({ index, formik, productData }: any) => {
    const { t } = useTranslation();
    const modelRef = useRef<any>(null);
    const [carData, setCarData] = useState<ICarData[]>([]);
    const [carMakeOptions, setCarMakeOptions] = useState<TOptions[]>([])
    const [carModelOptions, setCarModelOptions] = useState<TOptions[]>([])

    const { data, isSuccess } = useQuery({
        queryKey: ["carData"],
        queryFn: () => generalGet(`/admin/makes?with_models=1&with_variants=1`),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        const reqData = data?.data?.data
        setCarData(reqData)
        isSuccess && setCarMakeOptions(reqData?.map((car: ICarData) => {
            return { label: car.name, value: car.id }
        }))
    }, [isSuccess, data])

    useEffect(() => {
        if (productData) {
            const selectedCarMake = carData?.find((car: ICarData) => car.id === productData?.balloon_conditions[index]?.vehicle_model?.vehicle_make_id)
            const data: any = []
            selectedCarMake?.models?.map((car: any) => {
                data.push({ label: car.name, value: car.id })
            })
            setCarModelOptions(data)
        }
    }, [productData, index, carData])

    return (
        <>
            <div className="inputs_group">
                <FieldWrapper
                    customPadding
                    title={t("carMake")}
                    inputPlaceholder={t("selectCarMake")}
                    options={carMakeOptions}
                    onChange={(e) => {
                        if (modelRef) {

                            modelRef?.current?.clearValue()
                        }
                        const selectedCarMake = carData?.find((car: ICarData) => car.id === e?.value)
                        const data: any = []
                        selectedCarMake?.models?.map((car: any) => {
                            data.push({ label: car.name, value: car.id })
                        })
                        setCarModelOptions(data)
                    }}
                    selectStyle={customStyles}
                    select
                    defaultValue={productData && carMakeOptions?.find(option => option.value == productData?.balloon_conditions[index]?.vehicle_model?.vehicle_make_id)}
                    key={productData && carMakeOptions?.find(option => option.value == productData?.balloon_conditions[index]?.vehicle_model?.vehicle_make_id)}
                />
                <FieldWrapper
                    customPadding
                    title={t("carModel")}
                    inputName={`balloon_conditions.${index}.vehicle_model_id`}
                    inputError={formik?.errors?.balloon_conditions && formik?.errors?.balloon_conditions[index]?.vehicle_model_id}
                    inputTouched={formik?.touched?.balloon_conditions && formik?.touched?.balloon_conditions[index]?.vehicle_model_id}
                    inputPlaceholder={t("selectCarModel")}
                    selectRef={modelRef}
                    // ref={inputRef}
                    options={carModelOptions}
                    onChange={(e: any) => formik.setFieldValue(`balloon_conditions.${index}.vehicle_model_id`, e?.value)}
                    selectStyle={customStyles}
                    select
                    defaultValue={productData && carModelOptions?.find(option => option.value == productData?.balloon_conditions[index]?.vehicle_model?.id)}
                    key={productData && carModelOptions?.find(option => option.value == productData?.balloon_conditions[index]?.vehicle_model?.id)}
                />
            </div>
            <div className="inputs_group">
                <FieldWrapper title={t("salaryTransfer")}>
                    <RadioInput
                        name={`balloon_conditions.${index}.salary_transferred_to`}
                        onChange={(e: any) => formik.setFieldValue(`balloon_conditions.${index}.salary_transferred_to`, e.target.value)}
                        value={formik?.values?.balloon_conditions[index]?.salary_transferred_to}
                        key={formik?.values?.balloon_conditions[index]?.salary_transferred_to}
                        data={salaryTo}
                    />
                </FieldWrapper>
            </div>
            <div className="inputs_group">
                <FieldWrapper
                    title={t("balloonPercentage")}
                    inputName={`balloon_conditions.${index}.balloon_percentage`}
                    inputError={formik?.errors?.balloon_conditions && formik?.errors?.balloon_conditions[index]?.balloon_percentage}
                    inputTouched={formik?.touched?.balloon_conditions && formik?.touched?.balloon_conditions[index]?.balloon_percentage}
                    inputPlaceholder={`${t("ex")} 2`}
                    input
                    type="number"
                    showUnit
                    unit="%"
                />
                <FieldWrapper noPadding />
                <FieldWrapper noPadding />
            </div>
        </>
    );
}

export default BalloonsInputs;