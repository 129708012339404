import { createSlice } from "@reduxjs/toolkit"

interface IRoles {
	label: string
	value: string
}
const initialState = {
	roles: [
		{
			label: "",
			value: "",
		},
	],
}

const rolesSlice = createSlice({
	name: "rolesData",
	initialState,
	reducers: {
		setRoles: (state, action) => {
			state.roles = action.payload
		},
	},
})

export const setRolesData = rolesSlice.actions.setRoles
export default rolesSlice.reducer
