
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { generalGet, generalToggleStatus } from "../../API/api";
import { IService } from "../../modules/serviceProvider/types/interfaces";
import { IDistributor } from "../../modules/distributors/types/interfaces";
const Breadcrumbs = () => {

    const breadcrumbsData = useSelector((store: any) => store.breadCrumbsData.breadCrumbsData);
    const [isServiceDetails, setIsServiceDetails] = useState<boolean>(false)
    const [isDistributorDetails, setIsDistributorDetails] = useState<boolean>(false)
    const [serviceIsActive, setServiceIsActive] = useState<IService>()
    const [distributorIsActive, setDistributorIsActive] = useState<IDistributor>()
    const [loading, setLoading] = useState(false)
    const [refetch, setRefetch] = useState(false)
    const { t, i18n } = useTranslation()
    const { pathname } = useLocation()
    const { data, isSuccess, isLoading, } = useQuery({
        queryKey: ["service providers", isServiceDetails, i18n.language, breadcrumbsData, refetch],
        queryFn: () => generalGet(`/service-providers/${breadcrumbsData?.serviceProviderId}`),
        refetchOnWindowFocus: false,
        enabled: isServiceDetails === true
    });

    const { data: distData, isSuccess: distIsSuccess, isLoading: distIsLoading, } = useQuery({
        queryKey: ["Distributor", isServiceDetails, i18n.language, breadcrumbsData, refetch],
        queryFn: () => generalGet(`/distributors/${breadcrumbsData?.serviceProviderId}`),
        refetchOnWindowFocus: false,
        enabled: isServiceDetails === true
    });


    useEffect(() => {
        const reqData = data?.data.data
        if (isSuccess) {
            setServiceIsActive(reqData?.is_active)
        }
    }, [isSuccess, data, i18n])

    useEffect(() => {
        const reqData = distData?.data.data
        if (distIsSuccess) {
            setDistributorIsActive(reqData?.is_active)
        }
    }, [distData, distIsSuccess, i18n])

    useEffect(() => {
        if (pathname === `/services/${breadcrumbsData?.serviceProviderId}`) {
            setRefetch(true)
            setIsServiceDetails(true)
        }
        else if (pathname === `/distributors/${breadcrumbsData?.distributorId}`) {
            setRefetch(true)
            setIsDistributorDetails(true)
        } else {
            setIsServiceDetails(false)
            setIsDistributorDetails(false)
        }
    }, [pathname, breadcrumbsData])

    useEffect(() => {
        setRefetch(true)
    }, [pathname])

    const handleStatus = () => {
        setLoading(true)
        if (breadcrumbsData?.serviceProviderId) {
            generalToggleStatus(`/service-providers/${breadcrumbsData?.serviceProviderId}/toggle-active`).then(res => {
                setServiceIsActive(res.data.data.is_active)
                setLoading(false);
            })
        } else if (breadcrumbsData?.distributorId) {
            generalToggleStatus(`/distributors/${breadcrumbsData?.distributorId}/toggle-active`).then(res => {
                setDistributorIsActive(res.data.data.is_active)
                setLoading(false);
            })
        }
    }


    return (
        <div className="breadcrumbs">
            {/* <div className="links">
                {breadcrumbsData?.links?.map((link: { label: string, path: string }, index: number) => (
                    <div className={`link ${!link?.path && "dimmed"}`} key={index}>
                        {link?.path ?
                            <>
                                <Link to={link?.path} className="name">{link?.label}</Link>
                                <svg className="chevron" width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.128282 8.91192C0.0478822 8.85162 0.00768253 8.77122 0.00768252 8.67072C0.00768252 8.61042 0.0277824 8.53002 0.0880819 8.46972L3.72616 4.51005L0.0880816 0.530279C-0.0325175 0.40968 -0.0325176 0.208681 0.108181 0.0880823C0.228781 -0.0325165 0.429779 -0.0325165 0.550378 0.108182L4.40955 4.28895C4.53015 4.40955 4.53015 4.59045 4.40955 4.71105L0.550379 8.89182C0.449879 9.03252 0.248881 9.03252 0.128282 8.91192Z" fill="currentColor" />
                                </svg>
                            </>
                            :
                            <>
                                <span className="name">{link?.label}</span>
                                <svg className="chevron" width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.128282 8.91192C0.0478822 8.85162 0.00768253 8.77122 0.00768252 8.67072C0.00768252 8.61042 0.0277824 8.53002 0.0880819 8.46972L3.72616 4.51005L0.0880816 0.530279C-0.0325175 0.40968 -0.0325176 0.208681 0.108181 0.0880823C0.228781 -0.0325165 0.429779 -0.0325165 0.550378 0.108182L4.40955 4.28895C4.53015 4.40955 4.53015 4.59045 4.40955 4.71105L0.550379 8.89182C0.449879 9.03252 0.248881 9.03252 0.128282 8.91192Z" fill="currentColor" />
                                </svg>
                            </>
                        }
                    </div>
                ))}
            </div> */}
            <div className="titles">
                <h4 className="page_title">{breadcrumbsData.page_title}</h4>
                {isServiceDetails && <div className={`status ${serviceIsActive ? "active" : ""} ${(loading || isLoading) ? "loading" : ""}`} onClick={() => handleStatus()}>{
                    (loading || isLoading) ?
                        <div className="loader"></div> :
                        serviceIsActive ? t("active") : t("inactive")
                }</div>}
                {isDistributorDetails && <div className={`status ${distributorIsActive ? "active" : ""} ${(loading || isLoading) ? "loading" : ""}`} onClick={() => handleStatus()}>{
                    (loading || isLoading) ?
                        <div className="loader"></div> :
                        distributorIsActive ? t("active") : t("inactive")
                }</div>}
            </div>
        </div>
    );
}

export default Breadcrumbs;