import { useTranslation } from "react-i18next";
import CreateOfferForm from "../../../modules/products/components/CreateOfferForm";
import { useDispatch } from "react-redux";
import { setBreadCrumbsData } from "../../../store/redux/breadCrumbsData";
import { useParams } from "react-router-dom";

const CreateOffer = () => {
    const { t } = useTranslation()
    const { id } = useParams()
    const dispatch = useDispatch()

    dispatch(setBreadCrumbsData({
        links: [
        { label: t("products"), path: `/products` },
        { label: id, path: `/products/${id}` },
        { label: t("create_offer"), path: `/products/${id}/create-offer` },
        ],
        page_title: t("create_offer"),
    }))
    
    return (
        <div>
            <CreateOfferForm />
        </div>
    );
}

export default CreateOffer;