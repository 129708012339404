import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ErrorMessage } from "formik";

const RadioInput = ({ data, name, onChange, value }: any) => {

    return (
        <>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={value}
                name={`${name}`}
                onChange={onChange}
            >
                {data.map((item: string, index: number) => (
                    <FormControlLabel key={item} value={item} control={
                        <Radio
                            className='normal-cursor'
                            size="small"
                            sx={{
                                color: "black",
                                '&.Mui-checked': {
                                    color: "black",
                                },
                            }}
                        />} label={item} />
                ))}

            </RadioGroup>
            <p className="error">
                <ErrorMessage name={name || "defaultName"} />
            </p>
        </>
    );
}

export default RadioInput;