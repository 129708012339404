interface Props{
    title:string
    data:{
        key:string
        value:string
    }[]
    }
    const DistributorInfo = ({title,data}:Props) => {
        return (
            <div className="data-info">
            <h2>{title}</h2>
            <div className="cards-container">
                {data.map((item, index) => (
                    <div className="card" key={index}>
                        <h6>{item.key}</h6>
                        <p>{item.value}</p>
                    </div>
                ))}
            </div>
        </div>
        );
    }
    
    export default DistributorInfo;