import { FormikProps } from "formik";
import { TOptions } from "../../../../types/types";
import SectionHeader from "../../../../components/SectionHeader";
import FieldWrapper from "../../../../components/formInputs/FieldWrapper";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { customStyles } from "../../../../utils/SelectStyles";
import Button from "../../../../components/buttons/Button";
import { generalGet } from "../../../../API/api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

interface IProps {
    formik: FormikProps<any>;
    distributors: TOptions[]
}

interface IVOptions {
    label: string
    value: string
    price: string
}
const CustomerCarForm = ({ formik, distributors }: IProps) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const { searchData } = useSelector((store: any) => store.findDeal)
    const [vehiclesOptions, setVehiclesOptions] = useState<IVOptions[]>()
    const vRef = useRef<any>(null)

    const viewStock = () => {
        vRef?.current?.clearValue()
        setLoading(true)
        generalGet(`/vehicles?chassis=${formik.values.chassis_no}&variant_id=${searchData.variant_id}`)
            .then(data => {
                setLoading(false)
                setVehiclesOptions(data.data.data.map((item: any) => ({ label: item.variant.display_name, value: item.id, price: item.price })))
            }).catch((error) => {
                setLoading(false)
                toast.error(error?.response?.data?.message || t("somethingWrong"))
            })
    }

    return (
        <SectionHeader title={t("vehicleInfo")} customStyle="last">
            <div className="inputs_group">
                <FieldWrapper
                    customPadding
                    title={t("Distributor")}
                    inputName={`distributor_id`}
                    inputError={formik?.errors?.distributor_id as string}
                    inputTouched={formik?.touched?.distributor_id as boolean}
                    inputPlaceholder={t("selectDistributor")}
                    options={distributors}
                    onChange={(e: any) => formik.setFieldValue(`distributor_id`, e?.value)}
                    selectStyle={customStyles}
                    select
                />
                <FieldWrapper noPadding />
            </div>
            <FieldWrapper title={t("chooseFromStock")} noPadding>
                <div className="inputs_group special-mobile">
                    <FieldWrapper
                        inputName={`chassis_no`}
                        inputPlaceholder={t("searchByChassisNo")}
                        input
                        inputError={formik?.errors?.chassis_no as string}
                        inputTouched={formik?.touched?.chassis_no as boolean}
                    >
                        {!formik.errors.chassis_no && vehiclesOptions?.length === 0 ?
                            <span className="error">{t("noStock")}</span> :
                            vehiclesOptions?.length &&
                            vehiclesOptions?.length > 0 &&
                            !formik.values.vehicle_id &&
                            <span className="error success">{t("stockLoaded")}</span>
                        }
                    </FieldWrapper>
                    <FieldWrapper>
                        <Button
                            loading={loading}
                            disabled={loading || !formik.values.chassis_no}
                            onClick={!loading && formik.values.chassis_no && viewStock}
                            text={t("loadStock")} type={'button'} ></Button>
                    </FieldWrapper>
                </div>
            </FieldWrapper>
            <div className="inputs_group">
                <FieldWrapper
                    selectRef={vRef}
                    customPadding
                    title={t("vehicle")}
                    inputName={`vehicle_id`}
                    inputError={formik?.errors?.vehicle_id as string}
                    inputTouched={formik?.touched?.vehicle_id as boolean}
                    inputPlaceholder={t("selectVehicle")}
                    options={vehiclesOptions}
                    onChange={(e: any) => formik.setFieldValue(`vehicle_id`, e?.value)}
                    selectStyle={customStyles}
                    select
                    disabled={!vehiclesOptions || vehiclesOptions.length === 0}
                    noPadding
                />
                <FieldWrapper noPadding />
            </div>
        </SectionHeader>
    );
}

export default CustomerCarForm;