import { toast } from "react-toastify"

export const searchFilterLogic = (props: any) => {
	const { searchInput, listOfData, keys, dropdownValue, dropdownKey } = props
	const regex = /^[a-zA-Z0-9\s.\u0600-\u06FF-]+$/
	if (!regex.test(searchInput)) {
		return []
	}

	return listOfData?.filter((item: any) =>
		keys.some((key: any, index: number) => {
			const nestedKeys = key.split(".") // Split nested key by dot
			let value = item
			for (const nestedKey of nestedKeys) {
				value = value?.[nestedKey]
				if (value === undefined) {
					return false // Stop iteration if nested key not found
				}
			}
			const result =
				value?.toString().toLowerCase().includes(searchInput.toLowerCase()) &&
				(!dropdownValue ||
					item?.[dropdownKey]?.some(
						(category: any) => category?.id == dropdownValue
					))
			return result
		})
	)
}

export const buildFormData = (
	formData: FormData,
	data: any,
	parentKey: string
) => {
	if (
		data &&
		typeof data === "object" &&
		!(data instanceof Date) &&
		!(data instanceof File)
	) {
		Object.keys(data).forEach((key) => {
			buildFormData(
				formData,
				data[key],
				parentKey ? `${parentKey}[${key}]` : key
			)
		})
	} else {
		const value = data == null ? "" : data

		formData.append(parentKey, value)
	}
}

export const handleSorting = (prop: any) => {
	const { setState, list, value, startTransition } = prop
	const originalList = [...list]
	if (value != "none") {
		const sortedList = originalList.sort((a: any, b: any) => {
			if (value == "is_active") return b[`${value}`] - a[`${value}`]
			return a[`${value}`] - b[`${value}`]
		})
		startTransition(() => {
			setState(sortedList)
		})
	}
}

//Table row selection
export const handleBulkSelection = (
	selectedItems: string[],
	setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>,
	id: string
) => {
	// console.log(selectedTeams, id, "ssss")
	if (selectedItems && selectedItems?.includes(id)) {
		setSelectedItems &&
			setSelectedItems((prev) => prev?.filter((item) => item !== id))
	} else {
		setSelectedItems && setSelectedItems((prev) => [...prev, id])
	}
}

export const scrollToError = (
	error: boolean,
	ref: React.RefObject<HTMLDivElement>,
	errorMsg?: string
) => {
	if (error) {
		ref?.current?.scrollIntoView({ behavior: "smooth" })
		toast.error(errorMsg || `Please fill all required fields`)
	}
}
