import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const ApplicationsTableContainer = ({ tableHeaders, data, noDataMessage, service }: any) => {

    const navigate = useNavigate()
    const { i18n } = useTranslation()

    return (
        <div className={`table_container`}>
            <div className="table_header">
                {tableHeaders?.map((header: any, index: number) => (
                    <span className={`head ${header.customClass}`} key={index}>{header.label}</span>
                ))}
            </div>
            <div className={`table_data ${(!data || data?.length == 0) && "no_data"}`}>
                {data?.length > 0 ?
                    <>
                        {data?.map((item: any, index: number) => (
                            <>
                                <div className="item clickable has_logo" key={index}
                                    onClick={() => {
                                        const contentContainer = document.querySelector(".layout_content");
                                        contentContainer?.scrollTo({ top: 0, behavior: "smooth" });
                                        navigate(`/applications/${item.id}`)
                                    }}
                                >
                                    <div className="column pro_id_col">{item?.id}</div>
                                    <div className="column registration_date_col text-center" >{`${item?.vehicle?.variant_id?.vehicle_model?.vehicle_make}, ${item?.vehicle?.variant_id?.vehicle_model?.name}`}</div>
                                    {!service && <div className="column capitalize text-center">{item?.service_provider?.[i18n.language]}</div>}
                                    <div className="column registration_date_col text-center">{item?.distributor}</div>
                                    <div className="column registration_date_col text-center">{item?.current_step?.name}</div>
                                    <div className="column registration_date_col text-center">{item?.status}</div>
                                    <div className="column registration_date_col text-center">  {moment(item?.updated_at).format('YYYY-MM-DD')}
                                    </div>
                                </div>
                            </>
                        ))}
                    </>
                    :
                    <h6>{noDataMessage}</h6>
                }
            </div>
        </div>
    );
}

export default ApplicationsTableContainer;