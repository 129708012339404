import { useDispatch } from "react-redux";
import { setBreadCrumbsData } from "../../../store/redux/breadCrumbsData";
import { useContext, useEffect, useState, useTransition } from "react";
import { useQuery } from "@tanstack/react-query";
import { generalGet } from "../../../API/api";
import TableSkeleton from "../../../components/loaders/TableSkeleton";
import ServicesTableContainer from "../../../modules/serviceProvider/components/ServicesTableContainer";
import ListHeader from "../../../components/ListHeader";
import SearchInputField from "../../../components/SearchInputField";
import { handleSorting, searchFilterLogic } from "../../../utils/HelperFunctions";
import { useTranslation } from "react-i18next";
import { authContext } from "../../../store/context/authContext";
import { customStyles, sortStyles } from "../../../utils/SelectStyles";
import Select, { GroupBase, OptionsOrGroups } from "react-select";
import { TOptions } from "i18next";
import { IDistributor } from "../../../modules/distributors/types/interfaces";
import DistributorTableContainer from "../../../modules/distributors/components/DistributorTableContainer";

const Distributors = () => {
    const { catchError } = useContext(authContext)
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    dispatch(setBreadCrumbsData({
        links: [{ label: t("links.distributors"), path: "/distributors" }],
        page_title: t("links.distributors"),
    }))
    const [selectedOption, setSelectedOption] = useState(null);
    const [searchInput, setSearchInput] = useState("")
    const [isPending, startTransition] = useTransition()
    const [refetch, setRefetch] = useState(false)
    const [shownList, setShownList] = useState<IDistributor[]>([])
    const [distributors, setDistributors] = useState<IDistributor[]>([])
    const { data, isSuccess, isLoading, error } = useQuery({
        queryKey: ["distributors", refetch, i18n],
        queryFn: () => generalGet("/distributors"),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        error && catchError(error)
    }, [error])

    useEffect(() => {
        const reqData = data?.data.data
        isSuccess && setDistributors(reqData.map((item: IDistributor) => ({ ...item })))
    }, [isSuccess, data])

    const tableHeaders = [
        { label: t("name"), customClass: "logo" },
        { label: t("address") },
        { label: t("phone") },
        { label: t("status"), customClass: "status_col" },
        { label: t("actions"), customClass: "actions_col" }
    ];

    useEffect(() => {
        setSelectedOption(null)
        setShownList(distributors)
        if (searchInput) {
            const results = searchFilterLogic({ searchInput: searchInput, listOfData: distributors, keys: ["id", "name"] });
            startTransition(() => {
                setShownList(results)
            })
        }
    }, [distributors, searchInput])

    const options: OptionsOrGroups<TOptions, GroupBase<TOptions>> = [
        {
            label: t("sort_by_status"), value: "is_active",
        },
        {
            label: t("created_at"), value: "id",
        }
    ]

    const applySorting = (e: any) => {
        setSelectedOption(e)
        handleSorting({ setState: setShownList, list: shownList, value: e.value, startTransition })
    }

    if (isLoading) return <TableSkeleton columns={6} withoutButton />

    return (
        <div className="distributors-page-container">
            <ListHeader>
                <div className="filter_container">
                    <SearchInputField placeholder={t("search_by_id_Name")} setSearchInput={setSearchInput} searchInput={searchInput} />
                    <Select
                        value={selectedOption}
                        placeholder={t("sort_by")}
                        isSearchable={false}
                        isClearable={false}
                        isMulti={false}
                        options={options}
                        className={`select-drop-down `}
                        noOptionsMessage={() => t("no_options")}
                        classNamePrefix="react-select"
                        styles={sortStyles as any}
                        onChange={applySorting}
                    />
                </div>
            </ListHeader>
            <DistributorTableContainer
                tableHeaders={tableHeaders}
                data={shownList}
                noDataMessage={t("No_distributor_found")}
                setRefetchData={setRefetch}
            />
        </div>
    );
}

export default Distributors;