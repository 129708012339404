import { useTranslation } from "react-i18next";
import ChangePasswordForm from "../../../modules/auth/components/ChangePasswordFrom";
import TextContainer from "../../../modules/auth/components/TextContainer";


const ChangePasswordPageComponents = () => {
    const {t}= useTranslation()
    return (
        <div className="login_page_container change_password_page">
            <TextContainer
                title={t("changePassword")}
                // desc="Forgot your password? Don't worry, we will send a reset password link to your email."
            />
            <ChangePasswordForm/>
        </div>
    );
}

export default ChangePasswordPageComponents;